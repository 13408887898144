<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Knock down
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-card class="card-shadow border-radius-xl">
                  <v-form ref="frm">
                    <v-card-text class="card-padding">
                      <v-row
                        ><v-col cols="12" md="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Auction center</label
                          >

                          <v-select
                            :items="auctionCenters"
                            item-text="auction_center_name"
                            item-value="id"
                            hide-details="auto"
                            v-model="auction_center"
                            @change="resetSeason"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-3
                            "
                            outlined
                            height="46"
                            :rules="[
                              (v) => !!v || 'Auction center is required',
                            ]"
                            placeholder="Select auction center"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="c_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0"
                                  >{{ item.auction_center_code }}-{{
                                    item.auction_center_name
                                  }}</span
                                >
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Season</label
                          >

                          <v-select
                            :items="season"
                            item-value="id"
                            hide-details="auto"
                            v-model="editedItem.season"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-3
                              mb-0
                            "
                            outlined
                            height="46"
                            @change="filterSeasonSale()"
                            single-line
                            :rules="[(v) => !!v || 'Season is required']"
                            placeholder="Select Season"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.name
                                }}</span>
                              </v-text>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.name
                                }}</span>
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Sale No</label
                          >

                          <v-select
                            :items="seasonSale"
                            item-value="id"
                            hide-details="auto"
                            v-model="editedItem.season_sale_id"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-3
                              mb-0
                            "
                            outlined
                            height="46"
                            single-line
                            :rules="[(v) => !!v || 'Sale No is required']"
                            placeholder="Select Sale No"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.season_sale
                                }}</span>
                              </v-text>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.season_sale
                                }}</span>
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Select file</label
                          >
                          <v-file-input
                            prepend-inner-icon
                            v-model="editedItem.file"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-3
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            placeholder="Upload Knock down"
                            :rules="[(v) => !!v || 'Knock down is required']"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="card-padding d-flex justify-end">
                      <v-btn
                        @click="close"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          py-3
                          px-6
                        "
                        >Cancel</v-btn
                      >

                      <v-btn
                        @click="save"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          py-3
                          px-6
                        "
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showConfirm" persistent max-width="1200">
        <v-card class="card-shadow border-radius-xl">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="data"
              mobile-breakpoint="0"
              fixed-header
              disable-pagination
              class="table"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title></v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-btn
                    @click="close"
                    elevation="0"
                    :ripple="false"
                    height="46"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mb-5
                      mt-5
                      mr-2
                    "
                    >Cancel</v-btn
                  >
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="46"
                    dark
                    @click="confirm"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                    "
                    >Proceed
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <GradeBulk
      v-if="dialog_bulk_grade"
      :dialog_bulk_grade="dialog_bulk_grade"
      :input_data="missing_grade"
      @close_dialog_bulk_grade="closeDialogBulkGrade()"
    />
    <MarkBulk
      v-if="!dialog_bulk_grade && dialog_bulk_mark"
      :dialog_bulk_mark="dialog_bulk_mark"
      :input_data="missing_mark"
      @close_dialog_bulk_mark="closeDialogBulkMark()"
    />
    <CategoryBulk
      v-if="!dialog_bulk_grade && !dialog_bulk_mark && dialog_bulk_category"
      :dialog_bulk_category="dialog_bulk_category"
      :input_data="missing_category"
      @close_dialog_bulk_category="closeDialogBulkCategory()"
    />
  </div>
</template>

<script>
// import axios from "axios";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import GradeBulk from "../../../master/Grade/views/GradeBulk.vue";
import MarkBulk from "../../../master/Mark/views/MarkBulk.vue";
import CategoryBulk from "../../../master/category/views/CategoryBulk.vue";
export default {
  components: {
    GradeBulk,
    MarkBulk,
    CategoryBulk,
  },
  name: "Knock down",
  data() {
    return {
      seasonSale: [],
      season: [],
      overlay: false,
      auctionCenters: [],
      auction_center: null,
      // ----------
      missing_grade: [],
      dialog_bulk_grade: false,
      missing_mark: [],
      dialog_bulk_mark: false,
      missing_category: [],
      dialog_bulk_category: false,
      page: 1,
      pageCount: 0,

      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        file: null,
        file_name: "",
        season_sale_id: 0,
        missing_data: [],
        season: 0, // not used in API- just to filter Sale No
      },
      defaultItem: {
        file: null,
        file_name: "",
        season_sale_id: 0,
        season: 0, // not used in API- just to filter Sale No
      },
      //
      showConfirm: false,
      data: [],
      headers: [
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Grade",
          value: "grade_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Invoice NO",
          value: "invoice_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Mark Name",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        text: message,
        type: "error",
        showConfirmButton: true,
        icon: "error",
      });
    },

    async initialize() {
      try {
        // this.seasonSale = await apiMaster.getSeasonSale();
        this.auctionCenters = await apiMaster.getAuctionCenter();
        this.season = await apiMaster.getSeason();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      if (this.auction_center) {
        this.overlay = true;
        this.editedItem.seasonSale = 0;
        this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
          this.editedItem.season,
          this.auction_center
        );
        this.overlay = false;
      }
    },
    closeDialogBulkGrade() {
      this.dialog_bulk_grade = false;
      this.missing_grade = [];
      if (
        !this.dialog_bulk_grade &&
        !this.dialog_bulk_mark &&
        !this.dialog_bulk_category
      ) {
        this.resend();
      }
    },
    closeDialogBulkMark() {
      this.dialog_bulk_mark = false;
      this.missing_mark = [];
      if (
        !this.dialog_bulk_grade &&
        !this.dialog_bulk_mark &&
        !this.dialog_bulk_category
      ) {
        this.resend();
      }
    },
    closeDialogBulkCategory() {
      this.dialog_bulk_category = false;
      this.missing_category = [];
      if (
        !this.dialog_bulk_grade &&
        !this.dialog_bulk_mark &&
        !this.dialog_bulk_category
      ) {
        this.resend();
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      console.log(item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Knock down Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.dialog = false;
      this.showConfirm = false;
      this.data = [];
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.frm.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        let filename = this.editedItem.file.name;

        let ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        if (ext == "xlsx") {
          this.overlay = true;
          try {
            let bodyFormData = new FormData();
            bodyFormData.append(
              "file",
              this.editedItem.file,
              this.editedItem.file.name
            );
            bodyFormData.append(
              "season_sale_id",
              this.editedItem.season_sale_id
            );
            let res = await api.create(bodyFormData);
            if (res.status === 201) {
              this.close();
              this.showSuccessAlert(`Knock down Successfully uploaded`);
            } else {
              this.data = [];
              this.showConfirm = false;

              if (res.confirmData?.res.length > 0) {
                this.editedItem.file_name = res.confirmData.file_name;
                this.showConfirm = true;
                this.data = res.confirmData.res;
                console.log("THIS/data", this.data);
              } else {
                this.editedItem.file_name = res.file_name;
                this.editedItem.missing_data = res.missing_data;
                this.showErrorAlert(
                  "Some data are missing. Please add them first to update Knock down"
                );
                if (res.missing_data.grade?.length > 0) {
                  res.missing_data.grade.map((item) => {
                    this.missing_grade.push({
                      grade_name: item.name,
                      grade_code: item.name,
                    });
                  });
                  this.dialog_bulk_grade = true;
                }
                if (res.missing_data.mark?.length > 0) {
                  res.missing_data.mark.map((item) => {
                    this.missing_mark.push({
                      mark_name: item.name,
                      mark_code: item.name,
                    });
                  });
                  this.dialog_bulk_mark = true;
                }
              }
            }
            this.overlay = false;
          } catch (err) {
            this.showErrorAlert(err.message);
            this.overlay = false;
          }
        } else {
          this.showWarningAlert("Please use .xlsx file ");
        }
        // this.close();
      }
    },
    resetSeason() {
      this.editedItem.season = 0;
      this.editedItem.season_sale_id = 0;
      this.seasonSale = [];
    },
    async resend() {
      try {
        this.overlay = true;
        let res = await api.resend({
          file_name: this.editedItem.file_name,
          season_sale_id: this.editedItem.season_sale_id,
          // auction_center: this.editedItem.auction_center,
        });
        if (res.status === 201) {
          this.close();
          this.showSuccessAlert(`Knock down Successfully uploaded`);
        } else {
          this.editedItem.file_name = res.file_name;
          this.editedItem.missing_data = res.missing_data;
          this.showErrorAlert(
            "Some data are missing. Please add them first to update Knock down"
          );
          if (res.missing_data.grade?.length > 0) {
            res.missing_data.grade.map((item) => {
              this.missing_grade.push({
                grade_name: item.name,
                grade_code: item.name,
              });
            });
            this.dialog_bulk_grade = true;
          }
          if (res.missing_data.mark?.length > 0) {
            res.missing_data.mark.map((item) => {
              this.missing_mark.push({
                mark_name: item.name,
                mark_code: item.name,
              });
            });
            this.dialog_bulk_mark = true;
          }
          if (res.missing_data.category?.length > 0) {
            res.missing_data.category.map((item) => {
              this.missing_category.push({
                category_name: item.name,
              });
            });
            this.dialog_bulk_category = true;
          }
        }
        this.overlay = false;
      } catch (err) {
        this.showErrorAlert(err.message);
        this.overlay = false;
      }
    },
    async confirm() {
      try {
        this.overlay = true;
        this.data = [];
        this.showConfirm = false;
        let res = await api.confirm({
          file_name: this.editedItem.file_name,
          season_sale_id: this.editedItem.season_sale_id,
          // auction_center: this.editedItem.auction_center,
        });
        if (res.status === 201) {
          this.close();
          this.showSuccessAlert(`Knock down Successfully uploaded`);
        }
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(err.message);
        this.overlay = false;
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Knock down" : "Edit Knock down";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
